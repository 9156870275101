import React from "react"
// import { MainImage } from "gatsby-plugin-image"
// import { Link } from "gatsby"

import Layout from "../_common/layout/default"
import portfolioWork1 from "./../../static/images/work/mosaics/mosaic-01.jpg"
import portfolioWork2 from "./../../static/images/work/mosaics/mosaic-02.jpg"
import portfolioWork3 from "./../../static/images/work/mosaics/mosaic-03.jpg"
import portfolioWork4 from "./../../static/images/work/mosaics/mosaic-04.jpg"
import portfolioWork5 from "./../../static/images/work/mosaics/mosaic-05.jpg"
import portfolioWork6 from "./../../static/images/work/mosaics/mosaic-06.jpg"
import portfolioWork7 from "./../../static/images/work/mosaics/mosaic-07.jpg"
import portfolioWork8 from "./../../static/images/work/mosaics/mosaic-08.jpg"
import portfolioWork9 from "./../../static/images/work/mosaics/mosaic-09.jpg"
import portfolioWork10 from "./../../static/images/work/mosaics/mosaic-10.jpg"
import portfolioWork11 from "./../../static/images/work/mosaics/mosaic-11.jpg"
import portfolioWork12 from "./../../static/images/work/mosaics/mosaic-12.jpg"

const Welcome_PT = ({ location }) => {
    return (
        <Layout location={location} title="Design & Ecology in Cement Products" >
            <main className="block">
                <article className="block" itemScopeitemType="http://schema.org/Article">
                    <header className="overflow-x-hidden py-4">
                        <div className="container">
                            <div className="row align-items-end mb-5 mt-lg-4">
                                <div className="col-lg-9 col-hd-8">
                                    <h1 className="display-4 ls-n2 mb-lg-0">
                                         Criação de uma nova, ecológica {" "}
                                        <br className="d-none d-xl-block" /> & amigável {" "}
                                        <span className="fw-bold text-primary"> indústria de produtos cimentícios</span>.
                                    </h1>
                                </div>
                                <div className="col-lg-3 col-hd-4 text-lg-end">
                                    <p className="fs-4 text-muted mb-1 lh-copy">Consulte-nos e <br className="d-none d-xl-block" /><a href="mailto:info@hidralstone.com" target="_blank" rel="noreferrer noopener" className="text-primary">solicite</a> uma cotação.</p>
                                </div>
                            </div>
                        </div>
                        <div className="container gallery-1 aos-init aos-animate" data-aos="fade-up">
                            <div className="row flex-nowrap align-items-end g-4 mb-4 skrollable skrollable-between" data-bottom-top="transform: translateX(-25%)" data-top-bottom="transform: translateX(-40%)" style={{ transform: "translateX(-30.7683%)" }}>
                                <div className="col-6 col-sm-4 col-md-3">
                                    <figure className="media media-scale ratio ratio-1x1">
                                        <span className="media-item" style={{ backgroundImage: 'url(' + portfolioWork1 + ')' }}></span>
                                    </figure>
                                </div>
                                <div className="col-6 col-sm-4 col-md-3">
                                    <figure className="media media-scale ratio ratio-1x1">
                                        <span className="media-item" style={{ backgroundImage: 'url(' + portfolioWork2 + ')' }}></span>
                                    </figure>
                                </div>
                                <div className="col-6 col-sm-4 col-md-3">
                                    <figure className="media media-scale ratio ratio-1x1">
                                        <span className="media-item" style={{ backgroundImage: 'url(' + portfolioWork3 + ')' }}></span>
                                    </figure>
                                </div>
                                <div className="col-6 col-sm-4 col-md-3">
                                    <figure className="media media-scale ratio ratio-1x1">
                                        <span className="media-item" style={{ backgroundImage: 'url(' + portfolioWork4 + ')' }}></span>
                                    </figure>
                                </div>
                                <div className="col-6 col-sm-4 col-md-3">
                                    <figure className="media media-scale ratio ratio-1x1">
                                        <span className="media-item" style={{ backgroundImage: 'url(' + portfolioWork5 + ')' }}></span>
                                    </figure>
                                </div>
                                <div className="col-6 col-sm-4 col-md-3">
                                    <figure className="media media-scale ratio ratio-1x1">
                                        <span className="media-item" style={{ backgroundImage: 'url(' + portfolioWork6 + ')' }}></span>
                                    </figure>
                                </div>
                                <div className="col-6 col-sm-4 col-md-3">
                                    <figure className="media media-scale ratio ratio-1x1">
                                        <span className="media-item" style={{ backgroundImage: 'url(' + portfolioWork7 + ')' }}></span>
                                    </figure>
                                </div>
                                <div className="col-6 col-sm-4 col-md-3">
                                    <figure className="media media-scale ratio ratio-1x1">
                                        <span className="media-item" style={{ backgroundImage: 'url(' + portfolioWork8 + ')' }}></span>
                                    </figure>
                                </div>
                                <div className="col-6 col-sm-4 col-md-3">
                                    <figure className="media media-scale ratio ratio-1x1">
                                        <span className="media-item" style={{ backgroundImage: 'url(' + portfolioWork9 + ')' }}></span>
                                    </figure>
                                </div>
                                <div className="col-6 col-sm-4 col-md-3">
                                    <figure className="media media-scale ratio ratio-1x1">
                                        <span className="media-item" style={{ backgroundImage: 'url(' + portfolioWork10 + ')' }}></span>
                                    </figure>
                                </div>
                            </div>
                            <div className="row flex-nowrap align-items-start g-4 skrollable skrollable-between" data-bottom-top="transform: translateX(-50%)" data-top-bottom="transform: translateX(-35%)" style={{ transform: "translateX(-48.5531%)" }}>
                                <div className="col-6 col-sm-4 col-md-3">
                                    <figure className="media media-scale ratio ratio-1x1">
                                        <span className="media-item" style={{ backgroundImage: 'url(' + portfolioWork4 + ')' }}></span>
                                    </figure>
                                </div>
                                <div className="col-6 col-sm-4 col-md-3">
                                    <figure className="media media-scale ratio ratio-1x1">
                                        <span className="media-item" style={{ backgroundImage: 'url(' + portfolioWork8 + ')' }}></span>
                                    </figure>
                                </div>
                                <div className="col-6 col-sm-4 col-md-3">
                                    <figure className="media media-scale ratio ratio-1x1">
                                        <span className="media-item" style={{ backgroundImage: 'url(' + portfolioWork9 + ')' }}></span>
                                    </figure>
                                </div>
                                <div className="col-6 col-sm-4 col-md-3">
                                    <figure className="media media-scale ratio ratio-1x1">
                                        <span className="media-item" style={{ backgroundImage: 'url(' + portfolioWork12 + ')' }}></span>
                                    </figure>
                                </div>
                                <div className="col-6 col-sm-4 col-md-3">
                                    <figure className="media media-scale ratio ratio-1x1">
                                        <span className="media-item" style={{ backgroundImage: 'url(' + portfolioWork10 + ')' }}></span>
                                    </figure>
                                </div>
                                <div className="col-6 col-sm-4 col-md-3">
                                    <figure className="media media-scale ratio ratio-1x1">
                                        <span className="media-item" style={{ backgroundImage: 'url(' + portfolioWork1 + ')' }}></span>
                                    </figure>
                                </div>
                                <div className="col-6 col-sm-4 col-md-3">
                                    <figure className="media media-scale ratio ratio-1x1">
                                        <span className="media-item" style={{ backgroundImage: 'url(' + portfolioWork11 + ')' }}></span>
                                    </figure>
                                </div>
                                <div className="col-6 col-sm-4 col-md-3">
                                    <figure className="media media-scale ratio ratio-1x1">
                                        <span className="media-item" style={{ backgroundImage: 'url(' + portfolioWork7 + ')' }}></span>
                                    </figure>
                                </div>
                                <div className="col-6 col-sm-4 col-md-3">
                                    <figure className="media media-scale ratio ratio-1x1">
                                        <span className="media-item" style={{ backgroundImage: 'url(' + portfolioWork3 + ')' }}></span>
                                    </figure>
                                </div>
                            </div>
                        </div>
                    </header>
                    <section className="block pt-3 pb-xl-5 pt-xl-4" id="about">
                        <div className="container">
                            <h1 className="visually-hidden sr-only">Sobre</h1>
                            <div className="row gx-5 py-4">
                                <div className="col-12 col-md">
                                    <div className="small ff-aux fw-semi ls-1 text-primary text-uppercase">
                                        Porquê nós
                                    </div>
                                </div>
                                <div className="col-12 col-md-10 col-xl-9">
                                    <p className="fs-4 fw-book">
                                        <strong>HIDRALSTONE</strong> é marca de qualidade em pavimentos e revestimentos hidráulicos, garantindo sofisticação em produtos cimentícios ecológicos e sustentáveis, com design personalizado ao melhor preço para o setor da construção em geral.
                                    </p>
                                    <p className="fs-5 mb-lg-2 fw-book">
                                        Com mais de 30 anos de experiência e know-how nesta indústria, fabricamos mosaicos clássicos artesanais, ladrilhos e lajetas, inovando através de um portfólio de produtos com padrões e cores personalizadas, com grande capacidade de resposta a qualquer encomenda.
                                    </p>
                                    <p className="mb-4 fw-book o-77">
                                        É nosso objetivo contribuir para a criação de uma nova indústria de produtos cimentícios, ecológica e amiga do ambiente, através da oferta de produtos baseados num modelo produtivo que utilize processos de transformação que não exigem grandes consumos energéticos, que ofereçam soluções de reutilização de resíduos gerados por outras indústrias, e que seja um modelo de negócios baseado na sustentabilidade.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </section>
                </article>
            </main>
            <section className="block py-5 bg-hs text-white">
                <div className={"container container-base text-center"}>
                    <h2 className="lh-1 mb-2 fs-1">Vamos conversar!</h2>
                    <p className="o-66">Conheça nossos produtos e fale connosco através do nosso e-mail <u>info@hidralstone.com</u>.</p>

                    <a className="text-inherit fw-demi" href="mailto:info@hidralstone.com" target="_blank" rel="noreferrer noopener">- Comece aqui</a>
                </div>
            </section>
        </Layout>
    )
}

export default Welcome_PT
